<template>
	<div class="modal-settings">
		<div class="bg" v-tap="() => $emit('close')"></div>
		<div class="content">
			<div class="title">Paramètres</div>

			<ui-volume-control label="Volume des effets sonores" :volume="settings?.sounds?.volume" :muted="settings?.sounds?.muted" @change="({ volume, muted }) => changeVolume({ slug: 'sounds', volume, muted })" />
			<ui-volume-control label="Volume de la musique" :volume="settings?.musics?.volume" :muted="settings?.musics?.muted" @change="({ volume, muted }) => changeVolume({ slug: 'musics', volume, muted })" />
			<ui-volume-control label="Volume des voix" :volume="settings?.voices?.volume" :muted="settings?.voices?.muted" @change="({ volume, muted }) => changeVolume({ slug: 'voices', volume, muted })" />

			<div v-if="isConnected" class="row">
				<p class="label">Déconnexion</p>
				<div class="value">
					<ui-button class="logout-button" color="red" :block="true" v-tap="() => addModal({ slug: 'confirm-logout' })">Se déconnecter</ui-button>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import UiVolumeControl from '@/components/_global/ui/ui-volume-control.vue'

export default {
	props: {
		modal: { default: null }
	},
	computed: {
		...mapWritableState(useAppStore, [ 'settings' ]),
		...mapState(useUserStore, [ 'isConnected' ])
	},
	methods: {
		...mapActions(useAppStore, [ 'addModal' ]),
		changeVolume({ slug, volume, muted }) {
			playSound({ slug: 'click' })
			this.settings[slug].volume = minmax(volume, 0, 10)
			this.settings[slug].muted = muted
		},
	},
	components: {
		UiVolumeControl
	}
}
</script>

<style lang="stylus" scoped>


.modal-settings
	absolute 0
	flex center
	.bg
		absolute 0
	> .content
		width calc(100vw - 32px)
		max-width 440px
		padding 32px
		flex center column
		gap 8px
		border-radius 8px
		color #fff
		font-size 2rem
		.title
			width 100%
			margin 0 0 16px 0
			font-size 3.2rem
			text-align center
			border-bottom 3px solid blue
		.row
			width 100%
			min-height 72px
			padding 8px 0
			border-radius 8px
			.label
				width 100%
				flex-shrink 1
				text-align center
				.small
					font-size 1.6rem
			.value
				width 100%
				flex center
				gap 16px
				background-color #000
				padding 16px
				border-radius 8px
				color #fff
				.logout-button
					border-radius 8px
					&:after
						border-radius 8px

</style>
