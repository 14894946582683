<template>
	<div class="ui-input">
		<input :type="type"
			class="input" :class="{ error }"
			:placeholder="placeholder"
			:autocomplete="autocomplete"
			v-model="value"
		/>
	</div>
</template>

<script>
export default {
	props: {
		type: { default: 'text' },
		modelValue: { default: null },
		placeholder: { default: '' },
		autocomplete: { default: '' },
		error: { default: false },
	},
	computed: {
		value: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		}
	},
}
</script>

<style lang="stylus" scoped>

.ui-input
	display block
	.input
		width 100%
		height 40px
		padding 0 16px
		background #fff
		border none
		border-radius 4px
		font inherit
		color dark
		outline-offset 2px
		&:focus
			outline 3px solid blue
			background-color lighten(blue, 85%)
		&.error
			outline 3px solid red
			outline-offset 0
			background-color lighten(red, 75%)
			animation 0.2s error linear infinite
			@keyframes error
				0%
					transform translateX(0)
				25%, 75%
					transform translateX(4px)
				50%
					transform translateX(-4px)

</style>
