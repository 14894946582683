<template>
	<div class="character-badge">
		<div class="illustration" v-bg="characterIllustration"></div>
	</div>
</template>

<script setup>
import { useItineraries } from '@/composable/itineraries';

const {
	characterIllustration
} = useItineraries()

</script>

<style lang="stylus" scoped>

#mobile
	.character-badge
		right -24px
		bottom -20px
		width 96px
		height 96px

.character-badge
	position absolute
	right -24px
	bottom -24px
	width 140px
	height 140px
	border-radius 50% 50% 0 50%
	background-color #0F4B5A
	box-shadow 0 0 0 8px #05222B
	cursor pointer
	transform-origin bottom right
	transition 0.15s easeOutQuart
	.illustration
		absolute 0
		border-radius 50% 50% 0 50%
		background center center no-repeat
		background-size contain

</style>
