<template>
	<div class="modal-confirm-logout">
		<p>Vous êtes sur le point de vous déconnecter.</p>
		<p v-if="$route.name === 'game'">Si vous quittez maintenant, la progression de l'activité en cours sera perdue.</p>
		<p v-else>Vous pourrez retrouver votre progression en vous connectant à nouveau à ce compte.</p>
		<p><strong>Voulez-vous réellement vous déconnecter?</strong></p>
		<div class="buttons">
			<div class="button">
				<ui-button block="true" color="blue" v-tap="close">Non, je reste connecté</ui-button>
			</div>
			<div class="button">
				<ui-button block="true" color="red" :loading="loading" v-tap="leave">Oui, je me déconnecte</ui-button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			loading: false
		}
	},
	props: {
		popup: { default: null }
	},
	methods: {
		...mapActions(useUserStore, [ 'logout' ]),
		leave() {
			this.logout()
			this.$router.push({ name: 'home' })
			this.close()
		},
		close() {
			this.$emit('close')
		}
	},
}
</script>

<style lang="stylus" scoped>

.modal-confirm-logout
	flex center column
	max-width 500px
	padding 32px 32px 40px 32px
	background-color #fff
	border-radius 8px
	text-align center
	font-size 1.8rem
	color #46616E
	> p
		margin 0 0 16px 0
		strong
			font-weight 600
	.buttons
		position absolute
		left 32px
		top calc(100% - 32px)
		width calc(100% - 64px)
		flex center center
		gap 16px
		.button
			width 50%
			background-color #fff
			padding 4px
			border-radius 64px
			flex-shrink 0
		
	
</style>
