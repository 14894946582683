import { useMapStore } from "./map-store"
import { useUserStore } from "./user-store"

export const useTutoStore = defineStore({
	id: 'tuto',
	
	state: () => ({
		tutos: [],
		currentTuto: null,
		forcedTutos: [],
		tutoQueue: []
	}),
	
	getters: {
		
		getTutoMapping() {
			return {
				'6578836dc54fa0000b100870': {
					intro1: 'intro-1',
					intro2: 'intro-2',
					intro3: 'intro-3',
					journal1: 'journal-1',
					endFirstGame: 'end-first-game',
					endGame: 'end-game'
				},
				'65788f09c54fa00017100871': {
					intro1:  'intro-1-makwa',
					intro2:  'intro-2-makwa',
					intro3:  'intro-3-makwa',
					endGame: 'end-game-makwa',
					endItinerary: 'end-itineraire-contes-1',
					endNord: 'end-nord-contes-1',
					endQuebec: 'end-quebec-contes-1',
					endOntario: 'end-ontario-contes-1',
					endOuest: 'end-ouest-contes-1',
					endAtlantique: 'end-atlantique-contes-1'
				}
			}
		},
  
		onboardingTutos() {
			// TODO: penser correctement l'architecture des tutos et faire le refactor
			const tutoMapping = this.getTutoMapping
			return tutoMapping[useMapStore().currentCategoryId] ?? tutoMapping['6578836dc54fa0000b100870']
		}
	},
	actions: {
		
		async initTutos() {
			if (!this.tutos?.length) this.tutos = await getAllTutorials()
		},
		
		setCurrentTuto(value) {
			this.currentTuto = value
		}
	}
	
})


if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useTutoStore, import.meta.hot))
}
