import { getOrDefault, getImageOrDefault, getMediaOrNull } from "@/js/utils"
import { medias } from "@affordancestudio/engage-game"

export function getSuitcases(lootItems = []){
    return getOrDefault(() => (lootItems ?? [])
		.map(getSuitcase), 
		[])
}

function getSuitcase(clientLootItem){
	const clientMedias = clientLootItem.clientMedias ?? []
	const includeDocuIcon = (cm) => (cm.tags ?? []).includes('docu-decouverte-icon')
	return {
			id: clientLootItem.id,
			title: clientLootItem.document?.description,
			icon: getMediaOrNull(clientMedias.find(includeDocuIcon)?.id)?.base64,
			items: (clientLootItem?.formattedElements?.items ?? []).map(mapSuitcaseItem)
		}
}

function mapSuitcaseItem(item){
	if (item == null) return
	
	const { id, document, tags, formattedElements = {} } = item
	formattedElements.id = item.id
	const type = getItemTypeOrEmpty(tags ?? [])
	const isLink = formattedElements?.url?.tags?.includes('url')
	return {
		id,
		type: isLink ? 'link' : type,
		link: isLink ? formattedElements?.url?.value : undefined,
		position: document?.positionX ?? 1,
		rotation: document?.rotation ?? 0,
		zIndex: document?.zIndex ?? 1,
		tags,
		cover: getCoverSuitcaseItem(formattedElements?.image_couverture?.value),
		content: itemParsers[type](formattedElements),
	}
}

const itemParsers = {
	lettre: (item) => parseLetter(item, 'letter-open'),
	cahier: (item) => parseNotebook(item, 'notebook-open'),
	papier: (item) => parsePaper(item, 'paper-open'),
	phone: (item) => parsePhone(item, 'phone-open'),
	postcard: (item) => parseCard(item, 'postcard-open'),
	picture: (item) => parsePicture(item, 'picture-open'),
	empty: () => ({})
}

function parsePicture(item, defaultImgSlug){
	return {
		...parseCard(item, defaultImgSlug),
		title: item.titre?.value
	}
}

function parsePaper(item, defaultImgSlug){
	const parseTitle = (text) => {
		const splits = text?.split('<p>---</p>')
		if (splits?.length > 1) return splits[0]
		return null
	}
	const text = (item.page ?? [])[0]?.value
	return {
		bgImage: getImageOrDefault(item.image_contenu?.value, defaultImgSlug),
		text,
		title: parseTitle(text),
		content: parsePaperContent(text) ?? null
	}
}

function parsePaperContent(text){
	const splits = text?.replaceAll('<p>[chains-separator]</p>', '<span class="chains-separator"></span>')?.split('<p>---</p>') ?? []
	if (splits.length > 1) return splits[1]
	if (splits.length == 1) return splits[0]
	return null
}

function parseLetter(item, defaultImgSlug){
	return {
		id: item.id,
		title: '',
		bgImage: getImageOrDefault(item.image_contenu?.value, defaultImgSlug),
		text: item.text?.value
	}
}

function parseNotebook(formattedElements, defaultImgSlug){
	return {
		bgImage: getImageOrDefault(formattedElements?.image_contenu?.value, defaultImgSlug),
		pages: parsePages(formattedElements ?? [])
	}
}

function parsePages(formattedElements){
	const findValueByTag = (p, tag) => p.formattedElements.find(el => (el.tags ?? []).includes(tag))?.value
	return (formattedElements.pages ?? []).map(p => ({
		title: findValueByTag(p, 'titre') ?? '',
		image: getMediaOrNull(findValueByTag(p, 'slide')),
		text: findValueByTag(p, 'text') ?? ''
	}))
}

function parsePhone(item, defaultImgSlug){
	return {
		bgImage: getImageOrDefault(item.image_contenu?.value, defaultImgSlug),
		slides: (item.slider ?? []).map(s => ({ id: item.id, image: getMediaOrNull(s.value) }))
	}
}

function getItemTypeOrEmpty(tags){
	return Object.keys(itemParsers).find(tag => (tags ?? []).includes(tag)) ?? 'empty'
}

function parseCard(item, defaultImgSlug){
	const findElByTagAndType = (els, tag, type) => (els ?? []).find(e => (e.tags ?? []).includes(tag) && e.type == type)
	const leftPageImage = getMediaOrNull(findElByTagAndType(item.left, 'slide', 'media')?.value)
	const rightPageImage = getMediaOrNull(findElByTagAndType(item.right, 'slide', 'media')?.value)
	return {
		title: findElByTagAndType(item.left, 'titre', 'text')?.value ?? '',
		bgImage: getImageOrDefault(item.image_contenu?.value, defaultImgSlug),
		leftPageHtml: findElByTagAndType(item.left, 'page-left', 'text')?.value ?? '',
		leftPageImage: leftPageImage?.base64,
		leftPageImageAlt: leftPageImage?.document?.title,
		rightPageHtml: findElByTagAndType(item.right, 'page-right', 'text')?.value ?? '',
		rightPageImage: rightPageImage?.base64,
		rightPageImageAlt: rightPageImage?.document?.title,
	}
}


function getCoverSuitcaseItem(idImage){
	return idImage ? medias.get({ id: idImage })?.base64 : null
}

