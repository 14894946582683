import { GLOBAL_YUP_VALIDATIONS } from "@/helpers/form-helper"
import { loginStudent } from "@/requests/home-requests"

const { email, password } = GLOBAL_YUP_VALIDATIONS

export default {
    adminLoginForm: {
        title: 'Connexion à un compte de gestion',
        validationSchema: yup.object({ email: email(yup) }),
        fields: [
            {
                name: 'email',
                label: 'Courriel',
                as: 'input',
                autocomplete: 'email',
                showValidationMsg: true
            },
            {
                name: 'password',
                label: 'Mot de passe',
                as: 'input',
                type: 'password',
            },
            {
                name: 'link',
                label: 'Mot de passe oublié',
                as: 'a',
                route: 'forgot'
            },
            {
                name: 'submit',
                text: 'Confirmer',
                as: 'button',
                class: 'green'
            },
        ],
    },
    studentLoginForm: {
        title: 'Connexion à un compte de jeu avec code de groupe',
        fields: [
            {
                name: 'userName',
                label: 'Nom d\'usager',
                as: 'input',
            },
            {
                name: 'password',
                label: 'Mot de passe',
                as: 'input',
                type: 'password',
            },
            {
                name: 'submit',
                text: 'Confirmer',
                as: 'button',
                class: 'green'
            },
        ],
        submit: loginStudent
    },
    anonymousLoginForm: {
        title: 'Connexion à un compte de jeu sans code de groupe',
        validationSchema: yup.object({ email: email(yup) }),
        fields: [
            {
                name: 'email',
                label: 'Courriel',
                as: 'input',
                showValidationMsg: true
            },
            {
                name: 'password',
                label: 'Mot de passe',
                as: 'input',
                type: 'password',
            },
            {
                name: 'link',
                label: 'Mot de passe oublié',
                as: 'a',
                route: 'forgot'
            },
            {
                name: 'submit',
                text: 'Confirmer',
                as: 'button',
                class: 'green'
            },
        ],
    },

}