<template>
	<div :id="device" class="main" :class="{ 'low-perf': settings.lowPerformanceMode }">
		<div v-if="ready" class="content">
			<div v-bg="backgroundImage" class="bg"></div>

			<router-view v-slot="{ Component }">
				<transition name="route" appear mode="out-in">
					<component :is="Component" :class="{ blur: modals.length }" />
				</transition>
			</router-view>

			<transition name="tuto" appear>
				<tuto v-if="currentTuto" :tuto="currentTuto" :class="{ blur: modals.length }" @end="endTuto" />
			</transition>

			<transition-group name="modal">
				<modal v-for="modal in modals" :key="modal.id" :modal="modal" />
			</transition-group>

			<cookie-banner />

		</div>

		<transition name="loading-screen">
			<loading-screen v-if="!ready" />
		</transition>

		<div v-if="loading" class="loading"><ui-loader /></div>
	</div>
</template>

<script>
import { medias } from '@affordancestudio/engage-game'

export default {
	inject: ['tutorialService'],
	computed: {
		...mapState(useAppStore, [ 'modals', 'settings', 'device', 'ready', 'loading' ]),
		...mapWritableState(useUserStore, [ 'userJournal', 'userDiscoveries' ]),
		...mapState(useTutoStore, [ 'currentTuto' ]),
		backgroundImage() {
			return medias.findAllByTags({ tags: [ 'city-map' ] })?.[0]?.base64
		},
	},
	watch: {
		settings: {
			handler(settings) {
				this.applyVolumeSettings(settings)

				if (this.ready) {
					localStorage.setItem('settings', JSON.stringify(settings))
					localStorage.setItem('Language', settings.lang)
				}
			},
			immediate: true,
			deep: true
		},
		$route: {
			handler(route) {
				const music = route?.meta?.music
				if (music) playMusic({ slug: music })
				else if (music === false) stopAllMusics()
			},
			immediate: true,
			deep: true
		}
	},
	methods: {
		...mapActions(useAppStore, [ 'initApp', 'addModal', 'setRatio', 'removeModal' ]),
		applyVolumeSettings(settings) {
			const sounds = settings?.sounds ?? { volume: 0 }
			const musics = settings?.musics ?? { volume: 0 }
			const soundVolume = sounds.muted ? 0 : sounds.volume ?? 0
			const musicVolume = musics.muted ? 0 : musics.volume ?? 0

			changeSoundsVolume({ volume: soundVolume / 10 })
			changeMusicsVolume({ volume: musicVolume / 10 })
		},
		async endTuto() {
			await this.tutorialService.end(this.currentTuto)
		},
		keypress(e) {
			if (e.key === 'Escape') {
				if (this.modals.length) {
					if (!this.modals[this.modals.length - 1].unclosable) {
						this.removeModal(this.modals[this.modals.length - 1])
					}
				}
				else if (this.userJournal.visible) {
					this.userJournal.visible = false
				}
				else if (this.userDiscoveries.visible) {
					this.userDiscoveries.visible = false
				}
				else {
					this.addModal({ slug: 'settings', background: 'dark-blue', scrollable: false })
				}
			}
		},
		resize() {
			this.setRatio({ width: window.innerWidth, height: window.innerHeight })
		}
	},
	mounted() {
		window.addEventListener('resize', this.resize)
		window.addEventListener('keydown', this.keypress)
		this.resize()
		this.applyVolumeSettings(this.settings)
	},
	beforeUnmount() {
		window.removeEventListener('resize', this.resize)
		window.removeEventListener('keydown', this.keypress)
	},
	async created() {
		await this.initApp()
	}
}
</script>

<style lang="stylus">
@import './assets/css/reset.styl'

#app
	background-color dark
	flex center center

.main
	width 100%
	height 100%
	background-color #082D37
	user-select none
	> .content
		height 100%

		> .bg
			absolute -16px
			opacity 0.25
			filter blur(12px)
			background  center center no-repeat
			background-size cover !important

		> .blur
			filter blur(8px)

		> .dev-button
			position fixed
			right 8px
			top 8px
			width 64px
			height 64px
			flex center
			border-radius 4px
			background-color #111
			cursor pointer
			&:hover
				background-color #000
.loading
	absolute 0
	background-color alpha(#fff, 25%)
	flex center
	cursor wait
</style>
