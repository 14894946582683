<template>
	<div class="open-notebook" @click.stop>
		<div class="illustration" v-bg="bgImage"></div>
		<div class="content">
			<ui-scroll>
				<div class="inner">
					<div class="text left">
						<div v-if="titleLeft" class="title"><ui-text :text="titleLeft" /></div>
						<template v-if="textLeft">
							<div v-if="textLeft?.image?.base64" class="image">
								<img :src="textLeft.image.base64" :alt="textLeft.image.document?.title">
							</div>
							<ui-text v-else :text="textLeft" />
						</template>
					</div>
					<div class="text right">
						<div v-if="titleRight" class="title"><ui-text :text="titleRight" /></div>
						<template v-if="textRight">
							<div v-if="textRight?.base64" class="image">
								<img :src="textRight.base64" :alt="textRight?.document?.title">
							</div>
							<ui-text v-else :text="textRight" />
						</template>
					</div>
				</div>
			</ui-scroll>
			<div class="buttons">
				<div class="page-button prev" :class="{ disabled: !prevable}" @click="prev()"><ui-icon name="discoveries/chevron-left" size="32" /><span>Précédent</span></div>
				<div class="page-button next" :class="{ disabled: !nextable}" @click="next()"><span>Suivant</span><ui-icon name="discoveries/chevron-right" size="32" /></div>
			</div>
		</div>
	</div>
</template>

<script>
import { array, object, string } from 'yup'

export default {
	data() {
		return {
			currentPageIndex: 0
		}
	},
	props: {
		bgImage: {required: true, type: String},
		pages: { required: true, type: Array, validator(value, props){
			return array().required().of(object({
					title: string().nullable(),
					text: string().nullable(),
					image: object().nullable(),
				})).validate(value)
		}}
	},
	computed: {
		prevable() {
			return this.currentPageIndex > 0
		},
		nextable() {
			if (this.pages.length % 2 === 0) return this.currentPageIndex < this.pages.length - 2
			return this.currentPageIndex < this.pages.length - 1
		},
		pageLeft() {
			return this.pages?.[this.currentPageIndex] || null
		},
		pageRight() {
			return this.pages?.[this.currentPageIndex + 1] || null
		},
		titleLeft() {
			return this.pageLeft.title
		},
		textLeft() {
			return this.pageLeft?.image ?? this.pageLeft?.text
		},
		titleRight() {
			return this.pageRight?.title
		},
		textRight() {
			return this.pageRight?.image ?? this.pageRight?.text
		},
	},
	methods: {
		prev() {
			if (this.prevable) {
				this.currentPageIndex = minmax(this.currentPageIndex - 2, 0, this.pages.length - 1)
			}
		},
		next() {
			if (this.nextable) {
				this.currentPageIndex = minmax(this.currentPageIndex + 2, 0, this.pages.length - 1)
			}
		}
	}
}
</script>

<style lang="stylus" scoped>

#mobile, #tablet-vertical
	.open-notebook
		left -12px
		max-width 500px
		max-height none
		height auto
		margin-bottom 40px
		.illustration
			display none
		.content
			absolute auto
			position relative
			background-image linear-gradient(0deg, #f5f3f1 5%, #fffffd 5%, #fffffd 50%, #f5f3f1 50%, #f5f3f1 55%, #fffffd 55%, #fffffd 100%)
			background-size 40px 40px
			box-shadow 0 0 0 4px #F7931E, 2px 0 0 4px #F7931E
			border-radius 0 8px 8px 0
			&:after
				content ''
				absolute 0
				right 95%
				background linear-gradient(to right, alpha(#000, 50%), alpha(#FFFFFD, 0%))
				box-shadow inset 4px 0 darken(#F7931E, 25%)
			.ui-scroll
				.inner
					width 100%
					height 85vh
					max-height 600px
					padding 32px
			.title, .text
				position relative
				left auto
				top auto
				right auto
				width 100%
				height auto
				margin 0 0 16px 0
			.text
				padding 0 0 16px 0
				.image
					width 100%
					height auto
			.buttons
				bottom auto
				left 0
				right 0
				filter brightness(10)
				top 100%

.open-notebook
	width calc(100% - 32px)
	height calc(100% - 32px)
	max-width 800px
	max-height 550px

	.illustration
		absolute 0
		background center center no-repeat
		background-size contain

	.content
		absolute 0
		::v-deep(.ui-scroll)
			.ui-scroll-gradient
				display none

		.text
			position absolute
			width 38%
			height 65%
			&.left
				left 64px
				top 36px
			&.right
				right 64px
				top 36px
			.title
				margin 0 0 16px 0
				font-size 2.4rem
				line-height 1.2em
			::v-deep(.ui-text)
				> p
					margin 0 0 8px 0
			.image
				width 110%
				height 450px
				flex center
				img
					width 100%

		.buttons
			position absolute
			left 16px
			right 16px
			bottom 0
			flex space-between center
			gap 16px
			height 56px
			.page-button
				width 140px
				height 56px
				flex flex-start center
				cursor pointer
				color #B97443
				font-weight 600
				font-size 1.4rem
				text-transform uppercase
				&.next
					flex flex-end center
				&.disabled
					opacity 0.25
					cursor default
</style>
