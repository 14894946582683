import { createRouter, createWebHistory } from 'vue-router'
import { PreloadManager, RouterManager, SessionManager, ValidationManager, routerHelper } from './helpers/router-helper'
import { useAppStore } from './stores/app-store'
import { useMapStore } from './stores/map-store'
import forms from './data/forms'
import { getOnboarding, getPasswordReset } from './requests/home-requests'
import { useUserStore } from './stores/user-store'
import { useItineraries } from './composable/itineraries'
const Home = () => import('@/components/home/home.vue')
const TeacherPanel = () => import('@/components/teacher-panel/teacher-panel.vue')
const OnBoarding = () => import('@/components/onboarding/onboarding.vue')
const Autoform = () => import('@/components/onboarding/autoform.vue')
const LoginProfile = () => import('@/components/login/login-profile.vue')
const FormLogin = () => import('@/components/login/form-login.vue')
const FormForgot = () => import('@/components/onboarding/form-forgot.vue')
const FormSignUpAdmin = () => import('@/components/signup/form-signup-admin.vue')
const FormSignUpMember = () => import('@/components/signup/form-signup-member.vue')
const FormSignUpGamer = () => import('@/components/signup/form-signup-gamer.vue')
const ConfirmPlay = () => import('@/components/onboarding/confirm-play.vue')
const SignUpProfile = () => import('@/components/signup/signup-profile.vue')
const City = () => import('@/components/city/city.vue')
const Game = () => import('@/components/game/game.vue')
const Itinerary = () => import('@/components/home/itineraries.vue')
const Groups = () => import('@/components/teacher-panel/groups/groups-page.vue')
const GroupStudentsList = () => import('@/components/teacher-panel/groups/group-students-class-list.vue')
const GroupStudentsJournal = () => import('@/components/teacher-panel/groups/group-students-journal.vue')
const Scramble = () => import('@/components/game/scramble/step-scramble.vue')

const providers = {
	getOnboardingGameId: async() => (await getOnboarding())?.clientGames?.[0]?.id,
	getPasswordResetGameId: async() => (await getPasswordReset())?.clientGames?.[0]?.id,
	getUserStore: async () => useUserStore(),
	mainSection:  async () => await useAppStore().loadMainSectionIfNull(),
	updateMap: async () => {
		await useItineraries()?.loadItinerary()
		await useMapStore()?.updateMap()
	},
	updateCategories: async () => {
		await useItineraries()?.loadItinerary()
	}
}

const routes = [
	{ name: 'home', path: '/', component: Home, meta: { music: false } },
	{ name: 'signup', path: '/inscription', redirect: {name: 'signup-profile' }, component: OnBoarding, meta: { music: false }, children: [
		{ name: 'signup-profile', path: '',  component: SignUpProfile },
		{ name: 'admin-signup', path: 'administrateur', component: FormSignUpAdmin },
		{ name: 'member-signup', path: 'membre', component: FormSignUpMember },
		{ name: 'gamer-signup', path: 'joueur', component: FormSignUpGamer },
	]},
	{ name: 'login', path: '/connexion', redirect: {name: 'login-profile' }, component: OnBoarding, meta: { music: false }, children: [
		{ name: 'login-profile', path: '',  component: LoginProfile },
		{ name: 'admin-login', path: 'administrateur', component: FormLogin, props: { schema: forms.adminLoginForm } },
		{ name: 'member-login', path: 'membre', component: FormLogin, props: { schema: forms.studentLoginForm } },
		{ name: 'gamer-login', path: 'joueur', component: FormLogin, props: { schema: forms.anonymousLoginForm } },
	]},
	{ name: 'teacher-panel', path: '/pedagogique', component: TeacherPanel, meta: { music: false, preload: providers.mainSection } },
	{ name: 'itineraries', path: '/itineraire', component: Itinerary, meta: { shouldBeAuth: true, preload: providers.updateCategories } },
	{ name: 'groups', path: '/groupes', component: Groups, meta: { shouldBeAuth: true }, children: [
		{ name: 'class-list', path: ':groupCode', component: GroupStudentsList },
		{ name: 'student', path: ':groupCode/:student', component: GroupStudentsJournal },
	]},
	{ name: 'onboarding', path: '/onboarding', redirect: {name: 'onboard' }, component: OnBoarding, props: { hideBackButton: true }, children: [
		{ name: 'password-reset', path: 'modifier-mot-de-passe', component: Autoform, props: { gameIdProvider: providers.getPasswordResetGameId }},
		{ name: 'onboard', path:'', component: Autoform, props: { gameIdProvider: providers.getOnboardingGameId }, meta: { music: false, shouldBeAuth: true } },
		{ name: 'forgot', path: 'mot-de-passe-oublie', component: FormForgot },
		{ name: 'confirm-play', path: 'confirmation', component: ConfirmPlay },
	]},
	{ name: 'city', path: '/jeu/canada', component: City,
		meta: { music: 'city-map', preload: providers.updateMap, shouldBeAuth: true,
			validate: routerHelper.validateAll([
				routerHelper.validators.redirectIfOnboardingIs(['is_confirmed', 'is_in_a_group'], 'onboarding'),
				routerHelper.validators.redirectIfShouldSelectItinerary('itineraries')
			])},
		children: [
			{ name: 'game', path: ':gameId', component: Game, meta: { music: 'game' } },
		]
	},
	{ name: 'scramble', path: '/mots-meles', component: Scramble, meta: { shouldBeAuth: true }},
]

const router = createRouter({
	history: createWebHistory(),
	routes
})

router.beforeEach(
	RouterManager
		.setManagers([new SessionManager(), new ValidationManager(), new PreloadManager()])
		.setProviders(providers)
		.manageRoutes()
		.beforeEach())

export default router
