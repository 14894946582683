<template>
	<transition name="cookie-banner" appear>
		<div v-if="visible && mainText" class="cookie-banner">
			<p @click.prevent="tapText" v-html="mainText"></p>
			<div class="buttons">
				<ui-button color="dark-blue" size="small" @click="accept">Accepter</ui-button>
				<ui-button color="dark-blue" size="small" @click="decline">Refuser</ui-button>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	data() {
		return {
			visible: true,
			text: '',
			googleAnalyticsId: 'G-K0DZMMV5G2',
		}
	},
	emits: [ 'accept', 'decline' ],
	computed: {
		...mapState(useAppStore, ['mainSection']),
		mainText() {
			return this.text.split('---')[0]
		},
		// confidentialityPoliticText() {
		// 	return this.text.split('---')?.[1]
		// }
	},
	methods: {
		...mapActions(useAppStore, [ 'addModal' ]),
		accept() {
			localStorage.setItem('cookie-banner', 'accepted')
			this.addAnalytics()
			this.close()
		},
		decline() {
			localStorage.setItem('cookie-banner', 'refused')
			this.close()
		},
		// tapText(e) {
		// if (e.target.nodeName === 'A' && this.confidentialityPoliticText) {
		// this.addModal({ slug: 'text', title: "Politique de confidentialité", text: this.confidentialityPoliticText })
		// }
		// },
		tapText(e) {
			if (e.target.nodeName === 'A') {
				window.open('https://affordance-terms-and-conditions.s3.amazonaws.com/Politique_Lio.pdf', '_blank')
			}
		},
		close() {
			this.visible = false
		},

		// Ajoute le code Google Analytics dans le <head> de la page (uniquement si on est en build)
		addAnalytics() {
			if (import.meta.env.PROD) {
				const googleAnalyticsId = this.googleAnalyticsId
				const head = document.getElementsByTagName("head")[0]
				const script = document.createElement('script')
				script.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=' + googleAnalyticsId)
				script.onload = function() {
					window.dataLayer = window.dataLayer || []
					function gtag(){ dataLayer.push(arguments) }
					gtag('js', new Date())
					gtag('config', googleAnalyticsId)
					head.insertBefore(script, head.children[1])
				}()
			}
		},
	},
	async created() {
		// Récupération du texte mainSection (spécifique à Lio)
		if(!this.mainSection)
			this.mainSection = await mainSection()

		this.text = this.mainSection?.contentTexts?.find(t => t?.tags?.includes('cookie-banner'))?.html || ""

		const cookieBanner = localStorage.getItem('cookie-banner')
		if (cookieBanner === 'accepted') this.addAnalytics()
		this.visible = !cookieBanner
	}
}
</script>

<style lang="stylus" scoped>

.cookie-banner
	position fixed
	bottom 32px
	right 0
	width 100%
	max-width 600px
	background-color #111
	padding 16px 24px
	border-radius 8px 0 0 8px
	z-index 2
	font-size 1.5rem
	color #eee
	&.cookie-banner-enter-active
		transition 0.25s easeOutQuart 0.5s
	&.cookie-banner-enter-from
		opacity 0
		transform translateX(64px)
	&.cookie-banner-leave-active
		transition 0.2s easeInQuart
	&.cookie-banner-leave-to
		opacity 0
		transform translateX(64px)
	> p
		margin 0 0 16px 0
		::v-deep(a)
			color inherit
			text-decoration underline
			cursor pointer
			&:hover
				text-decoration none
	.buttons
		flex end
		gap 8px
		color #111

</style>